<template>
    <input
        v-model="model"
        :type="type"
        class="shadow-sm mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full text-sm border-gray-300 rounded-md disabled:opacity-50"
        v-bind="$attrs"
        ref="inputElement"
    />
</template>

<script lang="ts" setup>
import { ref } from "vue";

const model = defineModel();

export interface Props {
    type?: string;
}

withDefaults(defineProps<Props>(), {
    type: "text"
});

const inputElement = ref<HTMLInputElement | null>(null);

const focus = () => {
    if (inputElement.value) {
        inputElement.value.focus();
    }
};

defineExpose({ focus });
</script>
